/**
 * @generated SignedSource<<ad1b4e6a8de0aa9e6bc9966b51dca477>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ChooseRoleQuery$variables = {};
export type ChooseRoleQuery$data = {
  readonly login: {
    readonly availableLoginEntities: ReadonlyArray<{
      readonly __id: string;
      readonly displayName: string | null;
      readonly loginRoleDescription: string;
    }> | null;
  } | null;
};
export type ChooseRoleQuery = {
  response: ChooseRoleQuery$data;
  variables: ChooseRoleQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "loginRoleDescription",
  "storageKey": null
},
v2 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__id",
      "storageKey": null
    }
  ]
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ChooseRoleQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Login",
        "kind": "LinkedField",
        "name": "login",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "availableLoginEntities",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ChooseRoleQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Login",
        "kind": "LinkedField",
        "name": "login",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "availableLoginEntities",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "aa3037daf1841dd89c6e2774429ba8bf",
    "id": null,
    "metadata": {},
    "name": "ChooseRoleQuery",
    "operationKind": "query",
    "text": "query ChooseRoleQuery {\n  login {\n    availableLoginEntities {\n      __typename\n      displayName\n      loginRoleDescription\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "7be3bb090df1d59e0b99108b187f206f";

export default node;
